/**
 * Nosy Schema (Structured Data)
 *
 * @export Schema
 */

import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import { useSiteSettings } from '../../hooks/use-site-settings'

const Schema = ( { schema = {} } ) => {
    // Grab site url from siteMetadata
    const { siteUrl = '' } = useSiteMetadata()

    // Grab business info from Sanity siteSettings
    const {
        seo: { seoTitle = '' },
        mainImage = {},
        contactPoints: {
            email = '',
            // phone = '',
            address = '',
            location: { lat = 0, lng = 0 }
        },
        socialUrls = []
    } = useSiteSettings()

    // Format adress for schema & pluck values - ⚠️ skip item at index 2 ("Newport") ⚠️
    const [streetAddress = '', , postalCode = ''] = address.split( '\n' )

    // Create Sanity Client
    const client = sanityClient( {
        projectId: process.env.GATSBY_SANITY_PROJECT_ID,
        dataset: process.env.GATSBY_SANITY_DATASET,
        apiVersion: '2021-03-25', // use current UTC date - see "specifying API version"!
        // token: process.env.SANITY_TOKEN, // or leave blank for unauthenticated usage
        useCdn: true // `false` if you want to ensure fresh data
    } )

    // Create builder using Sanity Client
    const builder = imageUrlBuilder( client )

    // Use the builder to create the url for our image
    const image = builder.image( mainImage ).width( 1920 ).height( 1080 ).dpr( 1 ).url()

    // Compose default schema
    const defaultSchema = {
        '@id': 'https://nosy.agency/#localbusiness',
        '@context': 'https://schema.org',
        '@type': 'LocalBusiness',
        name: seoTitle,
        image: image,
        url: siteUrl,
        // 'telephone': phone,
        priceRange: '$$',
        contactPoint: {
            '@type': 'ContactPoint',
            contactType: 'Get in touch',
            // 'telephone': phone,
            email: email
        },
        address: {
            '@type': 'PostalAddress',
            streetAddress: streetAddress,
            addressLocality: 'Isle of Wight',
            postalCode: postalCode,
            addressCountry: 'GB'
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: lat,
            longitude: lng
        },
        openingHoursSpecification: {
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
            opens: '09:00',
            closes: '17:00'
        },
        sameAs: [socialUrls.map( ( { url } ) => url )]
    }

    // Merge defaultSchema & schema json object passed in from props
    const mergedSchema = { ...defaultSchema, ...schema }

    return (
        <Helmet>
            <script type='application/ld+json'>
                {JSON.stringify( mergedSchema )}
            </script>
        </Helmet>
    )
}

Schema.propTypes = {
    schema: PropTypes.object
}

Schema.defaultProps = {
    schema: {}
}

export default Schema
