/**
 * Nosy parallax Motion
 *
 * @export MotionImages
 */
import {
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    SimpleGrid,
    useBreakpointValue
} from '@chakra-ui/react'
// import { motion, useViewportScroll } from 'framer-motion'
import { motion } from 'framer-motion'
import React from 'react'
import Image from '../image/image'

/**
 * Create Motion-powered components outside of component to prevent re-renders
 */
const MotionSimpleGrid = motion( SimpleGrid )
const MotionFlex = motion( Flex )
const MotionGrid = motion( Grid )
const MotionGridItem = motion( GridItem )

const ParallaxMotion = ( { images= [], disciplines = [] } ) => {

    const mediaRowHiddenX = useBreakpointValue( {
        base: '100vw',
        sm: '100vw',
        md: '100vw',
        lg: '100vw',
        xl: '15vw'
    } )

    const mediaRowVisibleX = useBreakpointValue( {
        base: '-100vw',
        sm: '-100vw',
        md: '-100vw',
        lg: '-100vw',
        xl: '-15vw'
    } )

    const disciplineRowHiddenX = useBreakpointValue( {
        base: '-100vw',
        sm: '-100vw',
        md: '-100vw',
        lg: '-100vw',
        xl: '-75vw'
    } )

    const disciplineRowVisibleX = useBreakpointValue( {
        base: '105vw',
        sm: '101vw',
        md: '100vw',
        lg: '100vw',
        xl: '75vw'
    } )

    /**
     * Generate grid positions for disciplines
     */
    const generateGridItemPosition = ( index ) => {
        let colStart, colEnd
        const itemsPerRow = 3
        const colSpan = 2

        // Less than items per row
        if ( index < itemsPerRow ) {
            colStart = index * 2 + 1
            // More than items per row, correct the offeset
        } else {
            colStart = index * 2 - colSpan * 2
        }
        // Reduce colEnd
        colEnd = colStart + colSpan

        // Define is odd or even for alternate styles
        const isEven = ( index + 1 ) % 2 === 0

        return [ colStart, colEnd, isEven ]
    }

    /**
     * Create animation variant objects
     */
    const duration = 4 // 2
    const delayOffset = 0.125
    
    const mediaRowVariants = {
        hidden: {
            x: mediaRowHiddenX
        },
        visible: ( custom ) => ( {
            x: mediaRowVisibleX,
            transition: {
                type: 'spring',
                bounce: 0,
                duration,
                delay: custom * delayOffset,
            }
        } )
    }

    const disciplineRowVariants = {
        hidden: {
            x: disciplineRowHiddenX
        },
        visible: ( custom ) => ( {
            x: disciplineRowVisibleX,
            transition: {
                type: 'spring',
                bounce: 0,
                duration,
                delay: custom * delayOffset
            }
        } )
    }

    // Set image sizes for different viewport sizes
    const imageWidths = useBreakpointValue( {
        base: 540,  // 0-48em
        md: 920,    // 48em-80em,
        xl: 1080,   // 80em+
    } )

    return (
        <Flex
            minHeight='80vh'
            height='max'
            overflow='hidden'
            direction='column'
            justifyContent='center'
            alignItems='center'
            py={40}
            position='relative'
            _after={{
                content: '""',
                position: 'absolute',
                width: 'full',
                height: 'full',
                background: 'linear-gradient(90deg, rgba(0,3,9,1) 0%, rgba(0,0,0,0) 20%, rgba(15,15,15,0) 80%, rgba(0,3,9,1) 100%)',
                zIndex: 5
            }}>
            <Container 
                m={0} 
                p={0} 
                minWidth={['300vw', null, null, null, '150vw', null]}
                centerContent>
                <MotionSimpleGrid
                    zIndex={1}
                    width='full'
                    columns={3}
                    spacing={6}
                    mb={12}
                    initial='hidden'
                    whileInView='visible'
                    exit='hidden'
                    viewport={{
                        once: process.env.NODE_ENV === 'production',
                        margin: '0% 0% -30% 0%',
                        amount: 'some'
                    }}>
                    {images && 
                        images.map( ( image, index ) => {
                            const {
                                asset: { _id: id }
                            } = image
                            return (
                                <MotionFlex
                                    key={index}
                                    custom={index}
                                    variants={mediaRowVariants}>
                                    <Image 
                                        key={id} 
                                        imageData={image} 
                                        width={imageWidths} />
                                </MotionFlex>
                            )

                        } )}
                </MotionSimpleGrid>
            </Container>
            <Container 
                m={0} 
                p={0} 
                minWidth={['300vw', null, null, null, null, '225vw']} 
                centerContent>
                <MotionGrid
                    zIndex={2}
                    width='full'
                    height='max'
                    overflowY='visible'
                    my={[-16, -40, null, null, null]}
                    templateRows='repeat(2, 1fr)'
                    templateColumns='repeat(7, 1fr)'
                    gap={6}
                    initial='hidden'
                    whileInView='visible'
                    exit='hidden'
                    viewport={{
                        once: process.env.NODE_ENV === 'production',
                        margin: '0% 0% -15% 0%',
                        amount: 'some'
                    }}>
                    {disciplines && 
                        disciplines.map( ( discipline, index ) => {
                        // Generate GridItem position
                            const [ colStart, colEnd, isEven ] = generateGridItemPosition( index )
                            return (
                                <MotionGridItem
                                    // bg={[
                                    //     'red.400',
                                    //     'orange.400',
                                    //     'yellow.400',
                                    //     'green.400',
                                    //     'teal.400',
                                    //     'blue.400'
                                    // ]}
                                    key={index}
                                    custom={index}
                                    display='flex'
                                    width='full'
                                    maxWidth='80vw'
                                    height='max'
                                    justifyContent='flex-start'
                                    overflow='visible'
                                    variants={disciplineRowVariants}
                                    colStart={colStart}
                                    colEnd={colEnd}>
                                    <Heading
                                        as='h2'
                                        whiteSpace='nowrap'
                                        width='max'
                                        height='max'
                                        fontSize={['3xl', '6xl', '7xl', '8xl', '9xl']}
                                        fontFamily={isEven ? 'heading' : 'accent'}
                                        color={isEven ? 'brand_grayscale.white' : null}
                                        sx={
                                            !isEven
                                                ? {
                                                    WebkitTextFillColor:
                                                      'transparent',
                                                    WebkitTextStrokeWidth: [1,2],
                                                    WebkitTextStrokeColor:
                                                      'brand_grayscale.white'
                                                }
                                                : null
                                        }>
                                        {discipline}
                                    </Heading>
                                </MotionGridItem>
                            )
                        } )}
                </MotionGrid>
            </Container>
        </Flex>
    )
}

export default ParallaxMotion
