/**
 * Nosy Index Frontpage Template
 *
 * @export Frontpage
 */
import {
    Box,
    Button,
    Center,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    LinkBox,
    LinkOverlay,
    SimpleGrid,
    Stack,
    Tag,
    Text,
    useBreakpointValue,
    Wrap,
    WrapItem
} from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import { graphql } from 'gatsby'
import React from 'react'
import Hero from '../../components/hero/hero'
import Image from '../../components/image/image'
import Layout from '../../components/layout/layout'
import Link from '../../components/link/link'
import ParallaxMotion from '../../components/parallax-motion/parallax-motion'
import Schema from '../../components/schema/schema'
import { BlogRow, EventsRow } from '../../components/sections'
import SEO from '../../components/seo/seo'
import {
    calloutSerializer,
    ctaSerializer,
    statementSerializer
} from '../../serializers'
import {
    generateImageOffsetSizes,
    generateMarginOffsets,
    mapEdgesToNodes
} from '../../utils/helpers'

// Query variables passed in from pageContext
export const query = graphql`
    query TEMPLATE_FRONTPAGE_QUERY($id: String!) {
        sanityPage(id: { eq: $id }) {
            title
            seo: _rawSeo(resolveReferences: { maxDepth: 10 })
            slug {
                current
            }
            heading: _rawHeading(resolveReferences: { maxDepth: 10 })
            excerpt: _rawExcerpt(resolveReferences: { maxDepth: 10 })
            imageGallery {
                images {
                    _key
                    ...ImageWithPreview
                }
            }
            disciplines
            statement: _rawStatement(resolveReferences: { maxDepth: 10 })
            featuredWork: _rawFeatured(resolveReferences: { maxDepth: 10 })
            featuredLogos {
                images {
                    _key
                    ...ImageWithPreview
                }
            }
            callout: _rawCallout(resolveReferences: { maxDepth: 10 })
            cta: _rawCta(resolveReferences: { maxDepth: 10 })
        }
        allSanityLandingPage(
            sort: { fields: title, order: ASC }
            filter: { title: { in: ["Rubbish Networking"] } }
        ) {
            edges {
                node {
                    id
                    title
                    slug {
                        current
                    }
                    mainImage {
                        ...ImageWithPreview
                    }
                    seo {
                        seoTitle
                        seoDescription
                    }
                }
            }
        }
        allSanityPost(
            sort: { fields: publishedAt, order: DESC }
            filter: { _id: { regex: "/^(?!drafts).*/" } }
            limit: 3
        ) {
            edges {
                node {
                    id
                    title
                    excerpt: _rawExcerpt
                    mainImage {
                        ...ImageWithPreview
                    }
                    slug {
                        current
                    }
                }
            }
        }
    }
`

const Frontpage = (props) => {
    // console.log( '🦄 ~ file: frontpage.js ~ line 112 ~ Frontpage ~ props', { props } )

    const {
        data: {
            sanityPage: {
                seo: { seoTitle = '', seoDescription = '' },
                heading = [],
                excerpt = [],
                statement = [],
                imageGallery: { images = [] },
                disciplines = [],
                featuredWork = [],
                featuredLogos: { images: featuredLogos = [] },
                callout = [],
                cta = []
            },
            allSanityLandingPage = [],
            allSanityPost = []
        }
    } = props

    // Map edges to nodes
    const events = mapEdgesToNodes(allSanityLandingPage)
    const posts = mapEdgesToNodes(allSanityPost)

    const featuredWorkImageWidths = useBreakpointValue({
        base: 380,
        sm: 735,
        md: 464,
        lg: 540
    })

    const featuredWorkImageHeights = (index) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useBreakpointValue({
            base: 480,
            sm: null,
            md: null,
            lg: generateImageOffsetSizes(index)
        })
    }

    const featuredLogosImageWidths = useBreakpointValue({
        base: 200,
        sm: 165,
        md: 167
    })

    return (
        <Layout>
            <Schema />
            <SEO
                title={seoTitle}
                description={seoDescription}
                omitSuffix={true}
            />
            <Hero
                heading={heading}
                excerpt={excerpt}
            />
            <ParallaxMotion
                images={images}
                disciplines={disciplines}
            />
            <Container
                maxWidth='6xl'
                mb={[20, 40]}>
                <Center mt={10}>
                    <Stack
                        direction={['column', 'row']}
                        spacing={8}>
                        <Button
                            as={Link}
                            to='/disciplines/'
                            size='lg'
                            variant='brand-outline'>
                            View disciplines
                        </Button>
                        <Button
                            as={Link}
                            to='/showreel/'
                            size='lg'
                            variant='brand-outline'>
                            View showreel
                        </Button>
                    </Stack>
                </Center>
            </Container>

            <Container
                maxWidth='6xl'
                mt={[20, 40]}
                mb={0}
                centerContent>
                <Flex
                    direction='column'
                    width='full'
                    height='full'
                    justifyContent='center'
                    alignItems='center'
                    maxWidth='4xl'
                    py={12}>
                    <PortableText
                        value={statement}
                        components={statementSerializer}
                    />
                </Flex>
            </Container>

            <Container
                maxWidth='6xl'
                my={[20, 40]}>
                <SimpleGrid
                    columns={[1, null, 2]}
                    spacing={[4, 6, 8, 10]}>
                    {featuredWork.map((work, index) => {
                        const imageHeight = featuredWorkImageHeights(index)
                        return (
                            <LinkBox
                                key={work.id}
                                role='group'
                                height='min'
                                mt={[0, null, generateMarginOffsets(index)]}>
                                <Stack
                                    direction='column'
                                    height='full'
                                    spacing={8}
                                    pb={10}>
                                    {work.featureImage && (
                                        <Image
                                            imageData={work.featureImage}
                                            width={featuredWorkImageWidths}
                                            height={imageHeight}
                                            sx={{
                                                width: 'full'
                                            }}
                                        />
                                    )}
                                    <LinkOverlay
                                        as={Link}
                                        to={
                                            work.slug
                                                ? `/work/${work.slug.current}/`
                                                : '#'
                                        }>
                                        <Heading
                                            as='h2'
                                            color='gray.100'
                                            transition='all 0.343s'
                                            _groupHover={{
                                                color: 'brand_secondary.500'
                                            }}
                                            fontSize={[
                                                'lg',
                                                'xl',
                                                '2xl',
                                                '3xl'
                                            ]}
                                            mb={4}>
                                            {work.title}
                                        </Heading>
                                        <Text
                                            fontSize='md'
                                            maxWidth='2xl'
                                            mx='auto'
                                            color='gray.400'>
                                            {work.heading}
                                        </Text>
                                    </LinkOverlay>
                                    <Box pb={6}>
                                        {work.discipline && (
                                            <Wrap spacing={2}>
                                                {work.discipline
                                                    .sort()
                                                    .map((discipline) => (
                                                        <WrapItem
                                                            key={discipline.id}>
                                                            <Tag
                                                                color='gray.200'
                                                                backgroundColor='whiteAlpha.300'>
                                                                {
                                                                    discipline.title
                                                                }
                                                            </Tag>
                                                        </WrapItem>
                                                    ))}
                                            </Wrap>
                                        )}
                                    </Box>
                                </Stack>
                            </LinkBox>
                        )
                    })}
                </SimpleGrid>
                <Center mt={10}>
                    <Button
                        as={Link}
                        to='/work/'
                        size='lg'
                        variant='brand-outline'>
                        View work
                    </Button>
                </Center>
            </Container>

            <Container
                maxWidth='6xl'
                my={[20, 40]}>
                <Grid
                    templateColumns={[
                        'repeat(2, 1fr)',
                        'repeat(4, 1fr)',
                        'repeat(6, 1fr)'
                    ]}
                    gap={6}
                    width='full'>
                    {featuredLogos &&
                        featuredLogos.map((logo) => (
                            <GridItem
                                key={logo._key}
                                rowSpan={1}
                                sx={{
                                    '&:last-child:nth-of-type(3n - 1)': {
                                        gridColumnEnd: ['auto', '-2', '-3']
                                    },
                                    '&:nth-last-child(2):nth-of-type(3n + 1)': {
                                        gridColumnStart: ['auto', '2', '3']
                                    }
                                }}>
                                <Image
                                    imageData={logo}
                                    mx='auto'
                                    objectFit='contain'
                                    width={featuredLogosImageWidths}
                                />
                            </GridItem>
                        ))}
                </Grid>
            </Container>

            <Container
                maxWidth='6xl'
                my={[20, 40]}>
                <Stack
                    direction='column'
                    spacing={12}
                    maxWidth='3xl'
                    my={12}>
                    <PortableText
                        value={callout}
                        components={calloutSerializer}
                    />
                    <Stack
                        direction='row'
                        spacing={8}>
                        <Button
                            as={Link}
                            to='/about/'
                            size='lg'
                            variant='brand-outline'>
                            About us
                        </Button>
                        <Button
                            as={Link}
                            to='/team/'
                            size='lg'
                            variant='brand-ghost'>
                            Meet the team
                        </Button>
                    </Stack>
                </Stack>
            </Container>

            <Container
                maxWidth='6xl'
                mb={[20, 40]}>
                <BlogRow posts={posts} />
            </Container>

            <Container
                maxWidth='6xl'
                mb={[20, 40]}>
                <EventsRow
                    heading='Events'
                    events={events}
                />
            </Container>

            <Container
                maxWidth='6xl'
                mb={[20, 40]}>
                <PortableText
                    value={cta}
                    components={ctaSerializer}
                />
            </Container>
        </Layout>
    )
}

export default Frontpage
