/**
 * Nosy Hero
 *
 * @export Hero
 */
import { Box, Button, Container, Fade, Flex, Stack } from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import React from 'react'
import useFontFaceObserver from 'use-font-face-observer'
import { heroSerializer } from '../../serializers'
import AnimatedNosyMark from '../animated-nosy-mark/animated-nosy-mark'
import Link from '../link/link'

const Hero = ( { heading = [], excerpt = [] } ) => {
    // Is font loaded?
    const fontLoaded = useFontFaceObserver( [
        { family: 'Modelica-Regular' },
        { family: 'Modelica-Bold' }
    ] )

    return (
        <Flex
            align='center'
            justify='center'
            wrap='no-wrap'
            minHeight='80vh'
            zIndex='base'
            overflow='hidden'
            px={8}
            mb={10}>
            <Container 
                maxWidth='6xl' 
                zIndex='docked' 
                position='relative'>
                <Fade in={fontLoaded}>
                    <Stack 
                        direction='column' 
                        spacing={10}>
                        <Box
                            display='block'
                            height={['90vh', '85vh', '90vh', '95vh', '100vh']}
                            width='full'
                            maxWidth='100%'
                            position='absolute'
                            top={['-35vh', null, '-35vh', null, '-40vh']}
                            right={['0vw', null, '-25vw', null]}
                            zIndex={-1}>
                            <AnimatedNosyMark />
                        </Box>

                        <Box 
                            pt={[72, 64, 0]}
                            width={['full', null, '80%', '75%']}>
                            <PortableText
                                value={heading}
                                components={heroSerializer} />
                        </Box>
                        <Box 
                            width={['full', null, '80%', '50%']} 
                            lineHeight={1.2}
                            sx={{
                                '& p:last-of-type': {
                                    marginBottom: 0

                                }
                            }}>
                            <PortableText
                                value={excerpt}
                                components={heroSerializer} />
                        </Box>
                        <Stack 
                            direction='row' 
                            spacing={8}>
                            <Button
                                as={Link}
                                to='/work/'
                                size='lg'
                                variant='brand-outline'>
                                View work
                            </Button>
                            <Button
                                as={Link}
                                to='/contact/'
                                size='lg'
                                variant='brand-ghost'>
                                Contact us
                            </Button>
                        </Stack>
                    </Stack>
                </Fade>
            </Container>
        </Flex>
    )
}

export default Hero
