/**
 * Nosy Animated Nosy Mark
 *
 * @export AnimatedNosyMark
 */
import React, { lazy, Suspense, useEffect, useState } from 'react'

/**
 * Utlise Suspense to lazy load chakra canvas component
 */
const ChakraCanvas = lazy( () => import( './chakra-canvas' ) )

/**
 * Create Chakra-UI-ified canvas to stage the GLTF object
 *
 * @returns <component>
 */
const AnimatedNosyMark = () => {
    const [isMounted, setIsMounted] = useState( false )

    useEffect( () => {
        setIsMounted( true )
    }, [] )

    return (
        <React.Fragment>
            {!isMounted ? null : (
                <Suspense fallback={null}>
                    <ChakraCanvas />
                </Suspense>
            )}
        </React.Fragment>
    )
}

export default AnimatedNosyMark
